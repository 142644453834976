import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Authentication from "../components/authentication";
import Backdrop from "@mui/material/Backdrop";

import Settings from "../components/settings";
import addressTabIcon from "../addressTabIcon.png";
import Loader from "../components/loader/Loader";
import HistoricalData from "../components/HistoricalData/HistoricalData";
import CustomModule from "../components/CustomModule/CustomModule";

const ZOHO = window.ZOHO;

export default function SettingsRoute() {
  const [api_key, set_api_key] = useState(null);
  const [authLoadingZoho, setAuthLoadingZoho] = useState(false);
  const [revokeLoadingZoho, setRevokeLoadingZoho] = useState(false);
  const [zohoAuth, setZohoAuth] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);

  const [cronRunningModules, setCronRunningModules] = useState([]);

  const [snackBarForError, setSnackBarForError] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [tabNumber, setTabNumber] = useState(0);
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [wrongApiKey, setWrongApiKey] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [fetchedModules, setFetchedModules] = useState({});

  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");

  //

  const tabText = {
    weight: 500,
    size: "16px",
    lineHeight: "24px",
    letterSpacing: "1%",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.38)",
  };

  const handleAuthCheckZoho = async ({ headers }) => {
    // for zoho auth verify
    const authCheck = await axios({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/verifyauth`,
      headers: headers,
    });

    if (authCheck?.data?.org?.length > 0) {
      /**
       * Create Default Widget
       */

      await usertDefaultModuleAddressSettings({
        zohoApiKey: headers.apikey,
        orgId: headers.orgid,
      });
      await createDefaultWidgets({
        zohoApiKey: headers.apikey,
        orgId: headers.orgid,
        dataCenterUrl: headers.accountsurl,
      });

      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
        const dataCenterMap = {
          US: "https://www.zohoapis.com",
          EU: "https://www.zohoapis.eu",
          AU: "https://www.zohoapis.com.au",
          IN: "https://www.zohoapis.in",
          China: "https://www.zohoapis.com.cn",
          JP: "https://www.zohoapis.jp",
        };
        setDataCenterUrl(dataCenterMap?.[currentEnv?.deployment] || "");

        const dataCenterData = {
          apiname: "easyaddressautocomplete__Data_Center_URL",
          value: dataCenterMap?.[currentEnv?.deployment] || "",
        };
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", dataCenterData);
        setZohoLoaded(true);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  const usertDefaultModuleAddressSettings = async ({ zohoApiKey, orgId }) => {
    const existingSettingResp = await axios({
      // url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?settingType=systemDefined`,
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "GET",
      headers: {
        apikey: zohoApiKey,
        connname: "easyaddressautocomplete",
        orgid: orgId,
        "content-type": "application/json",
      },
    });
    const existingSettings = existingSettingResp?.data?.data || [];
    let settingModuleMap = {};
    let existingSettingData = {};
    let returnMap = {};
    existingSettings.forEach((setting) => {
      settingModuleMap[setting.module_name] = setting?.setting_id;
      existingSettingData[setting.module_name] = JSON.parse(
        setting?.setting_schema || "{}"
      );
    });

    if (!settingModuleMap["Leads"]) {
      // Create Setting for Leads
      const leadSchema = {
        moduleName: {
          api_name: "Leads",
          plural_label: "Leads",
        },
        formatted_address: "",
        country_restrictions: [],
        selected_fields_in_all_blocks: {
          Street: "Street",
          City: "City",
          State: "State",
          Zip_Code: "Zip_Code",
          Country: "Country",
          easyaddressautocomplete__Latitude:
            "easyaddressautocomplete__Latitude",
          easyaddressautocomplete__Longitude:
            "easyaddressautocomplete__Longitude",
        },
        blocks: [
          {
            address_block_name: "Address-1",
            fieldMapping: {
              address_1: {
                id: "address_1",
                selected_field: {
                  api_name: "Street",
                  field_label: "Street",
                },
                google_response:
                  "${street_number.long_name}, ${route.long_name}",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 1",
                required: true,
              },
              address_2: {
                id: "address_2",
                selected_field: null,
                google_response: "",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 2",
              },
              city: {
                id: "city",
                selected_field: {
                  api_name: "City",
                  field_label: "City",
                },
                google_response: "${locality.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "City",
                required: true,
              },
              state: {
                id: "state",
                selected_field: {
                  api_name: "State",
                  field_label: "State",
                },
                google_response: "${administrative_area_level_1.short_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "State",
                required: true,
              },
              zip: {
                id: "zip",
                selected_field: {
                  api_name: "Zip_Code",
                  field_label: "Zip Code",
                },
                google_response: "${postal_code.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "Zip",
                required: true,
              },
              country: {
                id: "country",
                selected_field: {
                  api_name: "Country",
                  field_label: "Country",
                },
                google_response: "${country.long_name}",
                value: "",
                allowedTypes: ["country"],
                data_type: "country",
                label: "Country",
              },
              latitude: {
                id: "latitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Latitude",
                  field_label: "Latitude",
                },
                google_response: "${location.latitude}",
                value: "",
                allowedTypes: ["latitude"],
                data_type: "latitude",
                label: "Latitude",
              },
              longitude: {
                id: "longitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Longitude",
                  field_label: "Longitude",
                },
                google_response: "${location.longitude}",
                value: "",
                allowedTypes: ["longitude"],
                data_type: "longitude",
                label: "Longitude",
              },
            },
          },
        ],
        address_components_values: {
          street_number: {
            long_name: "195",
            short_name: "195",
          },
          route: {
            long_name: "Wellington Road",
            short_name: "Wellington Rd",
          },
          locality: {
            long_name: "Clayton",
            short_name: "Clayton",
          },
          administrative_area_level_2: {
            long_name: "City of Monash",
            short_name: "City of Monash",
          },
          administrative_area_level_1: {
            long_name: "Victoria",
            short_name: "VIC",
          },
          country: {
            long_name: "Australia",
            short_name: "AU",
          },
          postal_code: {
            long_name: "3168",
            short_name: "3168",
          },
          location: {
            latitude: -37.9156711,
            longitude: 145.1490949,
          },
        },
      };

      const crateLeadSettingResp = await axios({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",

        data: {
          settingSchema: leadSchema,
          settingType: "systemDefined",
          moduleApiName: "Leads",
          settingId: settingModuleMap["Leads"] || "",
        },
        headers: {
          apikey: zohoApiKey,
          connname: "easyaddressautocomplete",
          orgid: orgId,
          "content-type": "application/json",
        },
      });
      returnMap["Leads"] = crateLeadSettingResp?.data?.data?.setting_id || "";
    }

    if (!settingModuleMap["Contacts"]) {
      // Create Setting for Contacts
      const contactSchema = {
        moduleName: {
          api_name: "Contacts",
          plural_label: "Contacts",
        },
        formatted_address: "",
        country_restrictions: [],
        selected_fields_in_all_blocks: {
          Mailing_Street: "Mailing_Street",
          Mailing_City: "Mailing_City",
          Mailing_State: "Mailing_State",
          Mailing_Zip: "Mailing_Zip",
          Mailing_Country: "Mailing_Country",
          easyaddressautocomplete__Mailing_Latitude:
            "easyaddressautocomplete__Mailing_Latitude",
          easyaddressautocomplete__Mailing_Longitude:
            "easyaddressautocomplete__Mailing_Longitude",
          Other_Street: "Other_Street",
          Other_City: "Other_City",
          Other_State: "Other_State",
          Other_Zip: "Other_Zip",
          Other_Country: "Other_Country",
          easyaddressautocomplete__Other_Latitude:
            "easyaddressautocomplete__Other_Latitude",
          easyaddressautocomplete__Other_Longitude:
            "easyaddressautocomplete__Other_Longitude",
        },
        blocks: [
          {
            address_block_name: "Mailing Address",
            fieldMapping: {
              address_1: {
                id: "address_1",
                selected_field: {
                  api_name: "Mailing_Street",
                  field_label: "Mailing Street",
                },
                google_response:
                  "${street_number.long_name}, ${route.long_name}",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 1",
                required: true,
              },
              address_2: {
                id: "address_2",
                selected_field: null,
                google_response: "",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 2",
              },
              city: {
                id: "city",
                selected_field: {
                  api_name: "Mailing_City",
                  field_label: "Mailing City",
                },
                google_response: "${locality.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "City",
                required: true,
              },
              state: {
                id: "state",
                selected_field: {
                  api_name: "Mailing_State",
                  field_label: "Mailing State",
                },
                google_response: "${administrative_area_level_1.short_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "State",
                required: true,
              },
              zip: {
                id: "zip",
                selected_field: {
                  api_name: "Mailing_Zip",
                  field_label: "Mailing Zip",
                },
                google_response: "${postal_code.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "Zip",
                required: true,
              },
              country: {
                id: "country",
                selected_field: {
                  api_name: "Mailing_Country",
                  field_label: "Mailing Country",
                },
                google_response: "${country.long_name}",
                value: "",
                allowedTypes: ["country"],
                data_type: "country",
                label: "Country",
              },
              latitude: {
                id: "latitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Mailing_Latitude",
                  field_label: "Mailing Latitude",
                },
                google_response: "${location.latitude}",
                value: "",
                allowedTypes: ["latitude"],
                data_type: "latitude",
                label: "Latitude",
              },
              longitude: {
                id: "longitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Mailing_Longitude",
                  field_label: "Mailing Longitude",
                },
                google_response: "${location.longitude}",
                value: "",
                allowedTypes: ["longitude"],
                data_type: "longitude",
                label: "Longitude",
              },
            },
          },
          {
            address_block_name: "Other Address",
            fieldMapping: {
              address_1: {
                id: "address_1",
                selected_field: {
                  api_name: "Other_Street",
                  field_label: "Other Street",
                },
                google_response:
                  "${street_number.long_name}, ${route.long_name}",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 1",
                required: true,
              },
              address_2: {
                id: "address_2",
                selected_field: null,
                google_response: "",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 2",
              },
              city: {
                id: "city",
                selected_field: {
                  api_name: "Other_City",
                  field_label: "Other City",
                },
                google_response: "${locality.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "City",
                required: true,
              },
              state: {
                id: "state",
                selected_field: {
                  api_name: "Other_State",
                  field_label: "Other State",
                },
                google_response: "${administrative_area_level_1.short_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "State",
                required: true,
              },
              zip: {
                id: "zip",
                selected_field: {
                  api_name: "Other_Zip",
                  field_label: "Other Zip",
                },
                google_response: "${postal_code.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "Zip",
                required: true,
              },
              country: {
                id: "country",
                selected_field: {
                  api_name: "Other_Country",
                  field_label: "Other Country",
                },
                google_response: "${country.long_name}",
                value: "",
                allowedTypes: ["country"],
                data_type: "country",
                label: "Country",
              },
              latitude: {
                id: "latitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Other_Latitude",
                  field_label: "Other Latitude",
                },
                google_response: "${location.latitude}",
                value: "",
                allowedTypes: ["latitude"],
                data_type: "latitude",
                label: "Latitude",
              },
              longitude: {
                id: "longitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Other_Longitude",
                  field_label: "Other Longitude",
                },
                google_response: "${location.longitude}",
                value: "",
                allowedTypes: ["longitude"],
                data_type: "longitude",
                label: "Longitude",
              },
            },
          },
        ],
        address_components_values: {
          street_number: {
            long_name: "195",
            short_name: "195",
          },
          route: {
            long_name: "Wellington Road",
            short_name: "Wellington Rd",
          },
          locality: {
            long_name: "Clayton",
            short_name: "Clayton",
          },
          administrative_area_level_2: {
            long_name: "City of Monash",
            short_name: "City of Monash",
          },
          administrative_area_level_1: {
            long_name: "Victoria",
            short_name: "VIC",
          },
          country: {
            long_name: "Australia",
            short_name: "AU",
          },
          postal_code: {
            long_name: "3168",
            short_name: "3168",
          },
          location: {
            latitude: -37.9156711,
            longitude: 145.1490949,
          },
        },
      };

      const crateContactSettingResp = await axios({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",

        data: {
          settingSchema: contactSchema,
          settingType: "systemDefined",
          moduleApiName: "Contacts",
          settingId: settingModuleMap["Contacts"] || "",
        },
        headers: {
          apikey: zohoApiKey,
          connname: "easyaddressautocomplete",
          orgid: orgId,
          "content-type": "application/json",
        },
      });
      returnMap["Contacts"] =
        crateContactSettingResp?.data?.data?.setting_id || "";
    }
    if (!settingModuleMap["Accounts"]) {
      // Create Setting for Accounts
      const accountSchema = {
        moduleName: {
          api_name: "Accounts",
          plural_label: "Accounts",
        },
        formatted_address: "",
        country_restrictions: [],
        selected_fields_in_all_blocks: {
          Billing_Street: "Billing_Street",
          Billing_City: "Billing_City",
          Billing_State: "Billing_State",
          Billing_Code: "Billing_Code",
          Billing_Country: "Billing_Country",
          easyaddressautocomplete__Billing_Latitude:
            "easyaddressautocomplete__Billing_Latitude",
          easyaddressautocomplete__Billing_Longitude:
            "easyaddressautocomplete__Billing_Longitude",
          Shipping_Street: "Shipping_Street",
          Shipping_City: "Shipping_City",
          Shipping_State: "Shipping_State",
          Shipping_Code: "Shipping_Code",
          Shipping_Country: "Shipping_Country",
          easyaddressautocomplete__Shipping_Latitude:
            "easyaddressautocomplete__Shipping_Latitude",
          easyaddressautocomplete__Shipping_Longitude:
            "easyaddressautocomplete__Shipping_Longitude",
        },
        blocks: [
          {
            address_block_name: "Billing Address",
            fieldMapping: {
              address_1: {
                id: "address_1",
                selected_field: {
                  api_name: "Billing_Street",
                  field_label: "Billing Street",
                },
                google_response:
                  "${street_number.long_name}, ${route.long_name}",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 1",
                required: true,
              },
              address_2: {
                id: "address_2",
                selected_field: null,
                google_response: "",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 2",
              },
              city: {
                id: "city",
                selected_field: {
                  api_name: "Billing_City",
                  field_label: "Billing City",
                },
                google_response: "${locality.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "City",
                required: true,
              },
              state: {
                id: "state",
                selected_field: {
                  api_name: "Billing_State",
                  field_label: "Billing State",
                },
                google_response: "${administrative_area_level_1.short_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "State",
                required: true,
              },
              zip: {
                id: "zip",
                selected_field: {
                  api_name: "Billing_Code",
                  field_label: "Billing Code",
                },
                google_response: "${postal_code.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "Zip",
                required: true,
              },
              country: {
                id: "country",
                selected_field: {
                  api_name: "Billing_Country",
                  field_label: "Billing Country",
                },
                google_response: "${country.long_name}",
                value: "",
                allowedTypes: ["country"],
                data_type: "country",
                label: "Country",
              },
              latitude: {
                id: "latitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Billing_Latitude",
                  field_label: "Billing Latitude",
                },
                google_response: "${location.latitude}",
                value: "",
                allowedTypes: ["latitude"],
                data_type: "latitude",
                label: "Latitude",
              },
              longitude: {
                id: "longitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Billing_Longitude",
                  field_label: "Billing Longitude",
                },
                google_response: "${location.longitude}",
                value: "",
                allowedTypes: ["longitude"],
                data_type: "longitude",
                label: "Longitude",
              },
            },
          },
          {
            address_block_name: "Shipping Address",
            fieldMapping: {
              address_1: {
                id: "address_1",
                selected_field: {
                  api_name: "Shipping_Street",
                  field_label: "Shipping Street",
                },
                google_response:
                  "${street_number.long_name}, ${route.long_name}",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 1",
                required: true,
              },
              address_2: {
                id: "address_2",
                selected_field: null,
                google_response: "",
                value: "",
                allowedTypes: ["text", "country"],
                data_type: "text",
                label: "Address 2",
              },
              city: {
                id: "city",
                selected_field: {
                  api_name: "Shipping_City",
                  field_label: "Shipping City",
                },
                google_response: "${locality.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "City",
                required: true,
              },
              state: {
                id: "state",
                selected_field: {
                  api_name: "Shipping_State",
                  field_label: "Shipping State",
                },
                google_response: "${administrative_area_level_1.short_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "State",
                required: true,
              },
              zip: {
                id: "zip",
                selected_field: {
                  api_name: "Shipping_Code",
                  field_label: "Shipping Code",
                },
                google_response: "${postal_code.long_name}",
                value: "",
                allowedTypes: ["text"],
                data_type: "text",
                label: "Zip",
                required: true,
              },
              country: {
                id: "country",
                selected_field: {
                  api_name: "Shipping_Country",
                  field_label: "Shipping Country",
                },
                google_response: "${country.long_name}",
                value: "",
                allowedTypes: ["country"],
                data_type: "country",
                label: "Country",
              },
              latitude: {
                id: "latitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Shipping_Latitude",
                  field_label: "Shipping Latitude",
                },
                google_response: "${location.latitude}",
                value: "",
                allowedTypes: ["latitude"],
                data_type: "latitude",
                label: "Latitude",
              },
              longitude: {
                id: "longitude",
                selected_field: {
                  api_name: "easyaddressautocomplete__Shipping_Longitude",
                  field_label: "Shipping Longitude",
                },
                google_response: "${location.longitude}",
                value: "",
                allowedTypes: ["longitude"],
                data_type: "longitude",
                label: "Longitude",
              },
            },
          },
        ],
        address_components_values: {
          street_number: {
            long_name: "187",
            short_name: "187",
          },
          route: {
            long_name: "Kent Street",
            short_name: "Kent St",
          },
          locality: {
            long_name: "Millers Point",
            short_name: "Millers Point",
          },
          administrative_area_level_2: {
            long_name: "City of Sydney",
            short_name: "City of Sydney",
          },
          administrative_area_level_1: {
            long_name: "New South Wales",
            short_name: "NSW",
          },
          country: {
            long_name: "Australia",
            short_name: "AU",
          },
          postal_code: {
            long_name: "2000",
            short_name: "2000",
          },
          location: {
            latitude: -33.8629262,
            longitude: 151.203742,
          },
        },
      };

      const crateAccountSettingResp = await axios({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",

        data: {
          settingSchema: accountSchema,
          settingType: "systemDefined",
          moduleApiName: "Accounts",
          settingId: settingModuleMap["Accounts"] || "",
        },
        headers: {
          apikey: zohoApiKey,
          connname: "easyaddressautocomplete",
          orgid: orgId,
          "content-type": "application/json",
        },
      });
      returnMap["Accounts"] =
        crateAccountSettingResp?.data?.data?.setting_id || "";
    }
    return returnMap;
  };

  const createDefaultWidgets = async ({ zohoApiKey, orgId, dataCenterUrl }) => {
    const resp = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
      method: "POST",
      data: {
        widgetName: "AddressToolkit by EasyPluginz",
        widgetUrl:
          "https://widgets.v1.addressautocomplete.easy-pluginz.com/relatedlist",
        widgetDescription:
          "This widget you can utilize in any Standard Module and Custom Module to visualize Current Address Location if Lattitude and Longitude informations are available",
        widgetType: "RELATED_LIST",
        apiDomain: dataCenterUrl,
      },
      headers: {
        apikey: zohoApiKey,
        connname: "easyaddressautocomplete",
        datacenterurl: dataCenterUrl,
        orgid: orgId,
      },
    });

    await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
      method: "POST",
      data: {
        widgetName: "AddressToolkit by EasyPluginz",
        widgetUrl:
          "https://widgets.v1.addressautocomplete.easy-pluginz.com/buttons",
        widgetDescription:
          "This widget you can utilize in any Standard Module and Custom Module to visualize Current Address Location if Lattitude and Longitude informations are available",
        widgetType: "CUSTOM_BUTTON",
        apiDomain: dataCenterUrl,
      },
      headers: {
        apikey: zohoApiKey,
        connname: "easyaddressautocomplete",
        datacenterurl: dataCenterUrl,
        orgid: orgId,
      },
    });
  };

  useEffect(() => {
    if (zohoLoaded) {
      (async () => {
        setLoadingMessage("Fetching Modules");
        try {
          //check zoho crm variable(easyaddressautocomplete__Google_API_KEY) for authentication
          //if there is data then make a simple api call to test it wheather it is correct or not
          //if api is valid then naviagte the user to settings tab
          //if api is not valid show error message
          //else navigate user to authentication tab
          // Get all Organization Varibales
          const orgData = {
            apiKeys: [
              "easyaddressautocomplete__API_KEY",
              "easyaddressautocomplete__Data_Center_URL",
              "easyaddressautocomplete__Organization_ID",
              "easyaddressautocomplete__ZAPI_Key",
              "easyaddressautocomplete__Google_API_KEY",
            ],
          };
          const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

          setDataCenterUrl(
            orgVariables?.Success?.Content
              ?.easyaddressautocomplete__Data_Center_URL?.value
              ? orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Data_Center_URL?.value != "null"
                ? orgVariables?.Success?.Content
                    ?.easyaddressautocomplete__Data_Center_URL?.value
                : dataCenterUrl
              : dataCenterUrl
          );
          let zohoapikey =
            orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
              ?.value;
          setZohoApiKey(zohoapikey);
          let orgid =
            orgVariables?.Success?.Content
              ?.easyaddressautocomplete__Organization_ID?.value;
          setOrgId(orgid);
          setZapiKey(
            orgVariables?.Success?.Content?.easyaddressautocomplete__ZAPI_Key
              ?.value
          );
          set_api_key(
            orgVariables?.Success?.Content
              ?.easyaddressautocomplete__Google_API_KEY?.value || ""
          );

          // Get List of Cron Jobs
          const resp = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
            method: "GET",
            headers: {
              orgid: orgid, // Org ID
              apikey: zohoapikey, // API KEy
              connname: "easyaddressautocomplete", // Conn Name
            },
          });

          let cronList = resp?.data?.data;

          //added null cheker here which created the issue
          let filteredList = cronList?.filter(
            (item) => item.status === "In Progress"
          );

          let runningModuleList = [];
          //added null cheker here which created the issue
          filteredList?.forEach((el) => {
            if (el.req_body.blockData.module_name) {
              runningModuleList.push(el.req_body.blockData.module_name);
            }
          });

          setCronRunningModules(runningModuleList || []);

          const authCheckStatus = await handleAuthCheckZoho({
            headers: {
              apikey:
                orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
                  ?.value,
              connname: "easyaddressautocomplete",
              orgid:
                orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Organization_ID?.value,
              accountsurl: dataCenterUrl,
              dataCenterurlvariablename:
                "easyaddressautocomplete__Data_Center_URL",
            },
          });
          //added null cheker here which created the issue
          if (
            !orgVariables?.Success?.Content
              ?.easyaddressautocomplete__Google_API_KEY?.value ||
            !authCheckStatus
          ) {
            return setTabNumber(0);
          }

          if (authCheckStatus) {
            // Create Default Settings
            await usertDefaultModuleAddressSettings({
              zohoApiKey:
                orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
                  ?.value,
              orgId:
                orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Organization_ID?.value,
            });
            await createDefaultWidgets({
              zohoApiKey:
                orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
                  ?.value,
              orgId:
                orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Organization_ID?.value,
              dataCenterUrl:
                orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Data_Center_URL?.value,
            });
            // Create Widgets
            setZohoAuth(true);
          }

          const testApi = await axios(
            `https://maps.googleapis.com/maps/api/geocode/json?place_id=ChIJeRpOeF67j4AR9ydy_PIzPuM&key=${orgVariables?.Success?.Content?.easyaddressautocomplete__Google_API_KEY?.value}`
          );

          if (testApi.data.status === "OK") setGoogleAuth(true);

          //
          if (testApi.data.status === "OK" && authCheckStatus) {
            setTabNumber(1);
          } else {
            setTabNumber(0);
            if (testApi.data.status !== "OK" && !authCheckStatus) {
              setSnackbarMsg(
                "Zoho is not authenticated and Google API Key is not valid"
              );
            } else if (testApi.data.status !== "OK") {
              setSnackbarMsg(testApi.data.error_message);
            } else if (!authCheckStatus) {
              setSnackbarMsg("Zoho is not authenticated");
            }

            setSnackBarForError(true);

            setWrongApiKey(true);
          }
          set_api_key(
            orgVariables?.Success?.Content
              ?.easyaddressautocomplete__Google_API_KEY?.value !== "null"
              ? orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Google_API_KEY?.value
              : ""
          );

          ZOHO.CRM.META.getModules().then(function (data) {
            const filteredModules = data.modules.filter((module) => {
              if (
                module.global_search_supported &&
                module.visible &&
                module.editable &&
                module.show_as_tab
              ) {
                return true;
              }
            });
            setModules(filteredModules);
          });
        } catch (error) {
          console.log(JSON.stringify(error));
        } finally {
          setLoading(false);
          setLoadingMessage(null);
        }
      })();
    }
  }, [zohoLoaded]);

  const handleAuthenticateZoho = async () => {
    setAuthLoadingZoho(true);

    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: zohoApiKey,
        zapiKey: zapiKey,
        accountsUrl:
          "https://accounts.zoho." +
          dataCenterUrl?.split("https://www.zohoapis.")?.[1],
        connName: "easyaddressautocomplete",
        scope: [
          // "ZohoCRM.users.CREATE",
          "ZohoCRM.users.READ",
          // "ZohoCRM.users.UPDATE",
          "ZohoCRM.org.READ",
          // "ZohoCRM.org.UPDATE",
          "ZohoCRM.settings.CREATE",
          "ZohoCRM.settings.READ",
          "ZohoCRM.settings.UPDATE",
          "ZohoCRM.coql.READ",
          "ZohoCRM.modules.CREATE",
          "ZohoCRM.modules.READ",
          "ZohoCRM.modules.UPDATE",
        ],
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/callback`,
        crmApiDomain: dataCenterUrl,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheckZoho({
        headers: {
          apikey: zohoApiKey,
          connname: "easyaddressautocomplete",
          orgid: orgId,
          accountsurl: dataCenterUrl,
          dataCenterurlvariablename: "easyaddressautocomplete__Data_Center_URL",
        },
      });

      if (authCheckStatus) {
        setZohoAuth(true);
        setAuthLoadingZoho(false);

        if (googleAuth) setTabNumber(1);

        clearInterval(myAuthCheckInterval);
      } else {
        setAuthLoadingZoho(false);
      }
    }, 5000);

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleRevokeZoho = async () => {
    setRevokeLoadingZoho(true);
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: zohoApiKey,
        connname: "easyaddressautocomplete",
      },
    });

    if (authUrlResp?.data) {
      setZohoAuth(false);
      setRevokeLoadingZoho(false);
    } else {
      setRevokeLoadingZoho(false);
    }
  };

  const handleFetchedModules = (module) => {
    setFetchedModules((prev) => ({
      ...prev,
      ...module,
    }));
  };
  const handleTabChange = (event, newValue) => {
    setTabNumber(newValue);
  };

  const handleSubmit = async (blockData, cvId) => {
    try {
      const resp = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "POST",
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: "easyaddressautocomplete", // Conn Name
        },
        data: {
          reqUrl: encodeURIComponent(
            `${dataCenterUrl}/crm/v2/functions/easyaddressautocomplete__executecron/actions/execute?auth_type=apikey&zapikey=${zapiKey}`
          ),

          reqParams: {},
          reqHeaders: {},
          reqBody: { blockData: blockData, cv_id: cvId },
          per_page: 50,
          reqType: "GET",
        },
      });
    } catch (error) {}
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
      </Backdrop>
    );
  }

  return (
    <>
      <Snackbar
        open={snackBarForError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }

          setSnackBarForError(false);
        }}
      >
        <MuiAlert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setSnackBarForError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
          {/* You might be entered a wrong API KEY. */}
        </MuiAlert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "26px",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "40px", height: "40px" }}
            src="./Address_tools_tip_Main_logo.png"
            alt="Logo"
          />
        </Box>

        <Typography
          sx={{
            maxWidth: "266px",
            height: "39px",
            ml: "12.48px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "25.6311px",
            lineHeight: "38px",
            textAlign: "center",
            letterSpacing: "-0.015em",
            textTransform: "capitalize",
            color: "#1E3056",
          }}
          variant="h5"
        >
          Google Address Toolkit
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",

          bgcolor: "background.paper",
          borderBottom: "1px solid rgba(232, 232, 232, 1)",
        }}
      >
        <Tabs value={tabNumber} onChange={handleTabChange} centered>
          {" "}
          <Tab
            sx={tabText}
            label="Authentication"
            disableFocusRipple
            disableRipple
          />
          <Tab
            sx={tabText}
            label="Settings"
            disableFocusRipple
            disableRipple
            disabled={wrongApiKey}
          />
          <Tab
            sx={tabText}
            label="Historical Data"
            disableFocusRipple
            disableRipple
            disabled={wrongApiKey}
          />{" "}
          {/* <Tab
            sx={tabText}
            label="Custom Module"
            disableFocusRipple
            disableRipple
            disabled={wrongApiKey}
          /> */}
          {/* <Tab sx={tabText} label="FAQ" /> */}
        </Tabs>
      </Box>
      {/* Authentication */}

      {tabNumber === 0 && (
        <Authentication
          ZOHO={ZOHO}
          dataCenterUrl={dataCenterUrl}
          setDataCenterUrl={setDataCenterUrl}
          prev_gooogle_api_key={api_key}
          zohoApiKey={zohoApiKey}
          handleAuthenticateZoho={handleAuthenticateZoho}
          handleRevokeZoho={handleRevokeZoho}
          authLoadingZoho={authLoadingZoho}
          revokeLoadingZoho={revokeLoadingZoho}
          zohoAuth={zohoAuth}
          setSnackbarMsg={setSnackbarMsg}
          setSnackBarForError={setSnackBarForError}
          setApiKey={(api_key) => {
            set_api_key(api_key);
          }}
          handleTableNumber={(tabNumber) => {
            setTabNumber(tabNumber);
            if (tabNumber === 0) {
              setWrongApiKey(true);
            } else {
              setWrongApiKey(false);
            }
          }}
          handleSnackBarError={() => {
            setSnackBarForError(true);
          }}
        />
      )}
      {/* Settings */}
      {tabNumber === 1 && (
        <Settings
          orgId={orgId}
          zohoApiKey={zohoApiKey}
          modules={modules}
          fetchedModules={fetchedModules}
          handleFetchedModules={handleFetchedModules}
          api_key={api_key}
          for_settings={true}
          cronRunningModules={cronRunningModules}
          handleSubmit={handleSubmit}
        />
      )}
      {tabNumber === 2 && (
        <HistoricalData
          orgId={orgId}
          zohoApiKey={zohoApiKey}
          dataCenterUrl={dataCenterUrl}
          zapiKey={zapiKey}
        />
      )}

      {/* {tabNumber === 3 && <CustomModule dataCenterUrl={dataCenterUrl} />} */}
    </>
  );
}
