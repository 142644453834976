import React, { useState, useLayoutEffect } from "react";
import GoogleMapReact from "google-map-react";
import MyMarker from "./MyMarker";
import {
  Box,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function GoogleMap({
  module,
  blockData,
  apiKey,
  dataCenterUrl,
  listViewWithoutRecordInfo,
}) {
  const [activeBlock, setActiveBlock] = useState("");
  const [data, setData] = useState([]);
  const [centerPositionOfMap, setCenterPositionOfMap] = useState(null);

  const [currentBound, setCurrentBound] = useState({});
  const [searchText, setSearchText] = useState("");

  useLayoutEffect(() => {
    if (!blockData) return;

    const firstBlockData = Object.values(blockData)[0];
    setActiveBlock(firstBlockData.Block_Id);
    setData(firstBlockData.Records);
    if (firstBlockData.Records.length > 0) {
      const mapCenterPosition = countCenterPosition(firstBlockData.Records);
      setCenterPositionOfMap(mapCenterPosition);
    }
  }, [blockData]);

  function countCenterPosition(data) {
    const LatMax = Math.max(...data.map((itm) => itm.Lat));
    const LatMin = Math.min(...data.map((itm) => itm.Lat));
    const LatCenter = (LatMax + LatMin) / 2;

    const LanMax = Math.max(...data.map((itm) => itm.Lan));
    const LanMin = Math.min(...data.map((itm) => itm.Lan));
    const LanCenter = (LanMax + LanMin) / 2;

    return { lat: LatCenter, lng: LanCenter };
  }

  const handleSetMaxMinLatLng = (event) => {
    let max_lat = -99999;
    let min_lat = 999999;
    let max_lng = -99999;
    let min_lng = 99999;

    const bounds = event.bounds;

    Object.values(bounds).forEach((bound) => {
      if (bound["lat"] > max_lat) {
        max_lat = bound["lat"];
      }
      if (bound["lng"] > max_lng) {
        max_lng = bound["lng"];
      }
      if (bound["lat"] < min_lat) {
        min_lat = bound["lat"];
      }
      if (bound["lng"] < min_lng) {
        min_lng = bound["lng"];
      }
    });

    setCurrentBound({
      minLat: min_lat,
      maxLat: max_lat,
      minLng: min_lng,
      maxLng: max_lng,
    });
  };
  const filterArray = (data, currentBound, searchText = "") => {
    const filteredData = data?.filter((item) => {
      return (
        item.Lat >= currentBound.minLat &&
        item.Lat <= currentBound.maxLat &&
        item.Lan >= currentBound.minLng &&
        item.Lan <= currentBound.maxLng &&
        (searchText === "" ||
          item.Name.toLowerCase().includes(searchText.toLowerCase()))
      );
    });

    return filteredData.length > 0 ? filteredData : null;
  };
  const handleChange = (event) => {
    setActiveBlock(event.target.value);
    setData(blockData[event.target.value].Records);
    const mapCenterPosition = countCenterPosition(
      blockData[event.target.value].Records
    );

    setCenterPositionOfMap(mapCenterPosition);
  };

  if (data.length === 0) {
    return (
      <Grid container spacing={1.5}>
        <Grid item xs={3}>
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Block</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activeBlock}
                label="Block"
                sx={{ textAlign: "left" }}
                onChange={handleChange}
              >
                {Object.values(blockData).map((block, blockIndex) => (
                  <MenuItem value={block.Block_Id} key={blockIndex}>
                    {block.Block_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              inputProps={{ autocomplete: "off" }}
              fullWidth
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              sx={{ mt: 1 }}
              size="small"
              placeholder={`Search ${module}...`}
            />
            <Box sx={{ mt: 1, height: 587, overflowY: "scroll" }}>
              {filterArray(data, currentBound, searchText)?.map(
                (item, index) => {
                  return (
                    <ListItemButton sx={{ p: 0 }} key={index}>
                      <ListItem sx={{ p: 0 }}>
                        <a
                          style={{
                            textDecoration: "none",
                          }}
                          href={`https://crm.zoho.${
                            dataCenterUrl.split("https://www.zohoapis.")[1]
                          }/crm/${
                            listViewWithoutRecordInfo.organization_name
                          }/tab/${listViewWithoutRecordInfo.module_name}/${
                            item.id
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ListItemText
                            primary={`${item.index}. ${item.Name}`}
                          />
                        </a>
                      </ListItem>
                    </ListItemButton>
                  );
                }
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              No records found for this setting.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={3}>
        <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Block</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={activeBlock}
              label="Block"
              sx={{ textAlign: "left" }}
              onChange={handleChange}
            >
              {Object.values(blockData).map((block, blockIndex) => (
                <MenuItem value={block.Block_Id} key={blockIndex}>
                  {block.Block_Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            inputProps={{ autocomplete: "off" }}
            fullWidth
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            sx={{ mt: 1 }}
            size="small"
            placeholder={`Search ${module}...`}
          />
          <Box sx={{ mt: 1, height: 587, overflowY: "scroll" }}>
            {filterArray(data, currentBound, searchText)?.map((item, index) => {
              return (
                <ListItemButton sx={{ p: 0 }} key={index}>
                  <ListItem sx={{ p: 0 }}>
                    <a
                      style={{
                        textDecoration: "none",
                      }}
                      href={`https://crm.zoho.${
                        dataCenterUrl.split("https://www.zohoapis.")[1]
                      }/crm/${
                        listViewWithoutRecordInfo.organization_name
                      }/tab/${listViewWithoutRecordInfo.module_name}/${
                        item.id
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ListItemText primary={`${item.index}. ${item.Name}`} />
                    </a>
                  </ListItem>
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      </Grid>
      <Grid item sx={{ height: window.ZOHO_MODAL_HEIGHT - 30 }} xs={9}>
        <GoogleMapReact
          bootstrapURLKeys={{
            // remove the key if you want to fork
            key: { apiKey },
          }}
          defaultCenter={centerPositionOfMap}
          defaultZoom={3.8}
          onChange={(event) => handleSetMaxMinLatLng(event)}
        >
          {filterArray(data, currentBound, searchText)?.map((item, index) => {
            return (
              <MyMarker
                key={index}
                lat={Number(item.Lat)}
                lng={Number(item.Lan)}
                module={module}
                data={item}
                listViewWithoutRecordInfo={listViewWithoutRecordInfo}
                dataCenterUrl={dataCenterUrl}
              />
            );
          })}
        </GoogleMapReact>
      </Grid>
    </Grid>
  );
}
