import React, { useState } from "react";
import Geocode from "react-geocode";

import Settings from "../settings";

const CustomAction = ({
  ZOHO,
  api_key,
  moduleInfo,
  fetchedInfo,
  show_settings_btn,
  fetchedModules,
  modules,
  handleFetchedModules,
  fetchDataOfThatModule,
  orgId,
  zohoApiKey,
}) => {
  const [map, setMap] = useState(null);
  //Google api
  const api = api_key;

  //Geocode Pacakge Setup
  Geocode.setApiKey(api_key);
  Geocode.setLocationType("ROOFTOP");

  Geocode.enableDebug(true);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  //this will trigger to add settings
  if (show_settings_btn) {
    return (
      <>
        <Settings
          modules={modules}
          fetchedModules={fetchedModules}
          fetchDataOfThatModule={fetchDataOfThatModule}
          handleFetchedModules={handleFetchedModules}
          api_key={api}
          for_settings={false}
          module_for_buttons={{
            plural_label: moduleInfo.plural_label,
            api_name: moduleInfo.module_name,
          }}
          zohoApiKey={zohoApiKey}
          orgId={orgId}
        />
      </>
    );
  }
  //this will trigger to edit settings of that module
  return (
    <Settings
      modules={modules}
      fetchedModules={fetchedModules}
      fetchDataOfThatModule={fetchDataOfThatModule}
      handleFetchedModules={handleFetchedModules}
      api_key={api}
      for_settings={true}
      edit_custom_module={true} //
      module_for_buttons={{
        plural_label: moduleInfo.plural_label,
        api_name: moduleInfo.module_name,
      }}
      zohoApiKey={zohoApiKey}
      orgId={orgId}
      custom_action={true}
    />
  );
};

export default CustomAction;
