import "./loader.css";

const Loader = () => {
  return (
    <div className="loaderParent">
      <h1 className="content">
        {/* <h1 className="ab">aaaaaaaa</h1> */}
        <span className="let1 spanLine">l</span>
        <span className="let2 spanLine">o</span>
        <span className="let3 spanLine">a</span>
        <span className="let4 spanLine">d</span>
        <span className="let5 spanLine">i</span>
        <span className="let6 spanLine">n</span>
        <span className="let7 spanLine">g</span>
      </h1>
    </div>
  );
};

export default Loader;
