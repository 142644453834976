import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TableLine = ({ field }) => (
  <Box
    sx={{
      color: "rgba(0, 0, 0, 0.6)",
      // bgcolor: "#F5F5F5",
    }}
  >
    <Box
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        alignItems: "center",
        width: "100%",
        py: 1,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ pl: 1, fontWeight: 400, fontSize: "14px" }}>
          {field.label}
        </Typography>
      </Box>
      <Box sx={{ flex: 2 }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {field?.selected_field?.field_label}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default function TableForCustomAction({ blocks }) {
  const [expanded, setExpanded] = React.useState(`panel_0`);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const list = blocks.map((blockData, blockIndex) => {
    return (
      <Accordion
        sx={{
          // bgcolor: "red",
          borderRadius: "4px",
          boxShadow: "none",
          "&.MuiPaper-root:before": {
            backgroundColor: "#ffffff",
          },
          "&.Mui-expanded": { mt: 0 },
          "&.MuiPaper-root": {
            mb: "8px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
        }}
        key={blockIndex}
        expanded={expanded === `panel_${blockIndex}`}
        onChange={handleChange(`panel_${blockIndex}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {blockData.address_block_name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ px: "12px", pb: "12px" }}>
            <Box
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                bgcolor: "#F5F5F5",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  py: 1,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ pl: 1, fontWeight: 500, fontSize: "14px" }}>
                    Settings
                  </Typography>
                </Box>
                <Box sx={{ flex: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Google Response
                  </Typography>
                </Box>
              </Box>
            </Box>

            {Object.values(blockData.fieldMapping).map((field) => (
              <TableLine field={field} />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  });
  return <>{list}</>;
}
