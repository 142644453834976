import React from "react";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./MyMarker.css";
import { Box } from "@mui/material";

const MyMarker = ({ module, data, listViewWithoutRecordInfo,dataCenterUrl }) => {
  return (
    <Tooltip
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            width: 200,
            p: 2,
            bgcolor: "white",
            "& .MuiTooltip-arrow": {
              color: "white",
              fontSize: 17,
            },
            color: "black",
          },
        },
      }}
      title={
        <Box>
          <a
            style={{ textDecoration: "none", fontSize: "16px" }}
            href={`https://crm.zoho.${dataCenterUrl.split('https://www.zohoapis.')[1]}/crm/${listViewWithoutRecordInfo.organization_name}/tab/${listViewWithoutRecordInfo.module_name}/${data.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {data?.Name}
          </a>
          <p style={{ margin: 0, marginTop: "5px" }}>{data?.Address}</p>
        </Box>
      }
      arrow
    >
      <Box sx={{ display: "flex" }}>
        <LocationOnIcon sx={{ color: "red" }} />
        <Box>
          <a
            style={{ textDecoration: "none" }}
            href={`https://crm.zoho.${dataCenterUrl.split('https://www.zohoapis.')[1]}/crm/${listViewWithoutRecordInfo.organization_name}/tab/${listViewWithoutRecordInfo.module_name}/${data.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ fontSize: "20px" }}>{data.index}</span>
          </a>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default MyMarker;
