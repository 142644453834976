import React, { useState, useEffect } from "react";
import axios from "axios";
import Geocode from "react-geocode";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import { LoadScript } from "@react-google-maps/api";

import Loader from "../components/loader/Loader";

const ZOHO = window.ZOHO;
const containerStyle = {
  width: "100%",
  height: "450px",
};
export default function RelatedList() {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [moduleInfo, setModuleInfo] = useState(null);
  const [allBlocksLatLong, setAllBlocksLatLong] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetchedInfo, setFetchedInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState(null);

  //Google api

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: api,
  //   libraries: ["places"],
  // });

  const [map, setMap] = React.useState(null);
  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  //Geocode Pacakge Setup
  Geocode.setApiKey(apiKey);
  Geocode.setLocationType("ROOFTOP");

  Geocode.enableDebug(true);

  useEffect(() => {
    setLoading(true);
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        ZOHO.CRM.UI.Resize({ height: "700", width: "1000" });
        const orgData = {
          apiKeys: [
            "easyaddressautocomplete__API_KEY",
            "easyaddressautocomplete__Organization_ID",
            "easyaddressautocomplete__ZAPI_Key",
            "easyaddressautocomplete__Data_Center_URL",
            "easyaddressautocomplete__Google_API_KEY",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        setDataCenterUrl(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Data_Center_URL?.value
            ? orgVariables?.Success?.Content
                ?.easyaddressautocomplete__Data_Center_URL?.value != "null"
              ? orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Data_Center_URL?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
            ?.value
        );
        setOrgId(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Organization_ID?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.easyaddressautocomplete__ZAPI_Key
            ?.value
        );
        setApiKey(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Google_API_KEY?.value
        );

        setZohoLoaded(true);
        setModuleInfo({
          module_name: data.Entity,
          data,
        });
        setLoading(false);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  useEffect(() => {
    if (zohoLoaded && moduleInfo) {
      (async () => {
        try {
          setLoading(true);
          // fetch related module setting schema
          const fetchSettingsData = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${moduleInfo.module_name}`,
            method: "GET",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: "easyaddressautocomplete", // Conn Name
            },
          });

          //if there is no data for this module
          //that means settings isnt created for this module
          if (!fetchSettingsData.data.data) {
            return setErrorMessage(
              `You didnt set settings for the ${moduleInfo.module_name} module`
            );
          }

          //fetch api key from organization variable
          // ZOHO.CRM.API.getOrgVariable("easyaddressautocomplete__Google_API_KEY").then(function (data) {

          //   setApiKey(data.Success.Content);
          // });

          //fetch all records of that module
          const fetchRecords = await ZOHO.CRM.API.getRecord({
            Entity: moduleInfo.module_name,
            RecordID: moduleInfo.data.EntityId,
          });
          const allRecords = await fetchRecords.data[0];

          //fetch all blocks latitude and longitude
          const setting_schema = JSON.parse(
            fetchSettingsData.data.data[0].setting_schema
          );

          //
          const allBlocksLatLong = setting_schema.blocks.flatMap((block) => {
            const block_name = block.address_block_name;
            const latitudeFieldName =
              block.fieldMapping.latitude?.selected_field?.api_name;
            const longitudeFieldName =
              block.fieldMapping.longitude?.selected_field?.api_name;
            const latitudeValue = allRecords[latitudeFieldName];
            const longitudeValue = allRecords[longitudeFieldName];

            if (!latitudeValue || !longitudeValue)
              return [
                {
                  ok: false,
                  message:
                    "To render google map you must have to add latitude and longitude",
                },
              ];

            return [
              {
                ok: true,
                block_name,
                latitude: Number(latitudeValue),
                longitude: Number(longitudeValue),
              },
            ];
          });
          setAllBlocksLatLong(allBlocksLatLong);

          setFetchedInfo({
            ...fetchSettingsData.data.data[0],
            setting_schema: JSON.parse(
              fetchSettingsData.data.data[0].setting_schema
            ),
          });
          setLoading(false);
        } catch (err) {
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [zohoLoaded, moduleInfo]);
  if (loading)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );

  return (
    allBlocksLatLong && (
      <Grid container spacing={2} sx={{ p: 1.5 }}>
        {allBlocksLatLong.map(
          ({ ok, block_name, latitude, longitude, message }, index) => {
            return (
              <LoadScript googleMapsApiKey={apiKey} libraries={["places"]}>
                <Grid
                  item
                  key={index}
                  xs={allBlocksLatLong.length > 1 ? 6 : 12}
                >
                  {ok ? (
                    <Box>
                      <Typography sx={{ mb: 1 }}>
                        Block Name: {block_name}
                      </Typography>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{
                          lat: latitude,
                          lng: longitude,
                        }}
                        zoom={16}
                      >
                        <Marker
                          position={{
                            lat: latitude,
                            lng: longitude,
                          }}
                          draggable={false}
                          label={{
                            text: "Origin",
                            fontSize: "18px",
                            fontWeight: "900",
                            color: "#333",
                          }}
                          zIndex={1000}
                          onUnmount={onUnmount}
                        />
                      </GoogleMap>
                    </Box>
                  ) : (
                    <Typography>{message}</Typography>
                  )}
                </Grid>
              </LoadScript>
            );
          }
        )}
      </Grid>
    )
  );
}
