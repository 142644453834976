import React, { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import settingPagePNG from "../../settingPagePNG.png";
import settingPageNoModulePNG from "../../settingPageNoModulePNG.png";

import AddAddressModal from "../../AddAddressModal";
import AddressBlockTables from "./AddressBlockTables";
import DialogDeleteAddress from "./Dialog";
import TableForCustomAction from "./TableForCustomAction";
import Loader from "../loader/Loader";
const ZOHO = window.ZOHO;
export default function Settings({
  orgId,
  zohoApiKey,
  modules,
  fetchedModules,
  handleFetchedModules,
  api_key,
  for_settings,
  module_for_buttons,
  fetchDataOfThatModule,
  handleSubmit,
  cronRunningModules,
  edit_custom_module,
  moduleInfo,
  custom_action,
  selectedLanguage,
  handleLanguage,
}) {
  const [open, setOpen] = useState(false);
  const [deleteAddressInfo, setDeleteAddressInfo] = useState(null);
  const [startPage, setStartPage] = useState(true);
  const [fetchedBlocks, setFetchedBlocks] = useState([]);
  const [existingData, setExistingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateSettings, setUpdateSettings] = useState(1);

  const handleOpen = () => {
    setOpen(true);
    setStartPage(true);
    setExistingData(null);
  };

  useMemo(() => {
    (async () => {
      try {
        //if there is module api name that means we have to fetch that specific module.this code is written for custom actions
        if (module_for_buttons) {
          const response = await axios.request({
            // url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${module_for_buttons.api_name}&settingType=systemDefined`,
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${module_for_buttons.api_name}`,
            method: "GET",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: "easyaddressautocomplete", // Conn Name
            },
          });

          const filteredData = response.data?.data?.map((blockData) => ({
            ...blockData,
            setting_schema: JSON.parse(blockData.setting_schema),
          }));

          setFetchedBlocks(filteredData);
          return;
        }
        const response = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
          method: "GET",
          headers: {
            orgid: orgId, // Org ID
            apikey: zohoApiKey, // API KEy
            connname: "easyaddressautocomplete", // Conn Name
          },
        });

        const filteredData = response.data?.data?.map((blockData) => ({
          ...blockData,
          setting_schema: JSON.parse(blockData.setting_schema),
        }));

        setFetchedBlocks(filteredData);
      } catch (err) {
        // alert(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [updateSettings]);

  const handleUpdateSettings = () => {
    setUpdateSettings((prev) => prev + 1);
    if (!for_settings) {
      fetchDataOfThatModule();
    }
  };
  const handleClose = () => setOpen(false);

  const handleSaveCustomAction = async (fetchedBlocks) => {
    const fetchedBlock = fetchedBlocks?.[0];
    const settingId = fetchedBlock?.setting_id || "";

    if (settingId !== "") {
      const resp = await ZOHO.CRM.ACTION.setConfig({
        moduleName: fetchedBlock?.module_name || "",
        settingId: settingId || "",
        orgid: fetchedBlock?.org_id || "",
        connname: "easyaddressautocomplete",
      });
    }
  };
  //this will open a modal for buttons,custom actions
  if (!for_settings) {
    return (
      <>
        <AddAddressModal
          openModal={open}
          fetchedModules={fetchedModules}
          api_key={api_key}
          modules={modules?.filter((module) => {
            if (
              fetchedBlocks?.find(
                (block) => block.module_name === module.api_name
              )
            ) {
              return false;
            } else {
              return true;
            }
          })}
          existingData={existingData}
          for_settings={for_settings}
          module_for_buttons={module_for_buttons}
          handleClose={handleClose}
          handleFetchedModules={handleFetchedModules}
          handleUpdateSettings={handleUpdateSettings}
          orgId={orgId}
          zohoApiKey={zohoApiKey}
          selectedLanguage={selectedLanguage}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
          }}
        >
          <Box
            sx={{
              width: "362px",
            }}
          >
            <img
              style={{
                display: "block",
                width: "228.46px",
                margin: "0 auto",
              }}
              src={settingPagePNG}
              alt="setting Page PNG"
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "150%",
                textAlign: "center",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.6)",
                my: "21px",
              }}
            >
              {`No settings found for ${module_for_buttons.plural_label} Module`}
            </Typography>
            <Tooltip
              title={`Add Settings For ${module_for_buttons.plural_label} Module`}
            >
              <Button
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  lineHeight: "26px",
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "26px",
                  letterSpacing: "0.46px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                size="medium"
                onClick={handleOpen}
              >
                <AddIcon sx={{ mr: "5px", fontSize: "16px" }} />

                <Typography>Add Settings</Typography>
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </>
    );
  }

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );
  }

  return (
    <Box sx={{ fontFamily: "Lato" }}>
      <DialogDeleteAddress
        open={!!deleteAddressInfo}
        loading={loading}
        handleClose={() => setDeleteAddressInfo(null)}
        handleAgree={async () => {
          try {
            setLoading(true);

            const updateData = await axios.request({
              url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${deleteAddressInfo.setting_id}`,
              method: "DELETE",
              headers: {
                orgid: orgId, // Org ID
                apikey: zohoApiKey, // API KEy
                connname: "easyaddressautocomplete", // Conn Name
              },
            });

            //if succesfully updated then upadte the state
            if (updateData.status === 200) {
              handleUpdateSettings();
              setDeleteAddressInfo(null);
            } else {
              // alert(JSON.stringify(updateData));
            }
          } catch (err) {
            // alert(err.message);
          } finally {
            setLoading(false);
          }
        }}
      />
      {/* this will open a modal for settings */}
      <AddAddressModal
        openModal={open}
        fetchedModules={fetchedModules}
        api_key={api_key}
        modules={modules?.filter((module) => {
          if (
            fetchedBlocks?.find(
              (block) => block.module_name === module.api_name
            )
          ) {
            return false;
          } else {
            return true;
          }
        })}
        for_settings={for_settings}
        existingData={existingData}
        handleClose={handleClose}
        handleFetchedModules={handleFetchedModules}
        handleUpdateSettings={handleUpdateSettings}
        orgId={orgId}
        zohoApiKey={zohoApiKey}
        selectedLanguage={selectedLanguage}
      />
      <Container
        sx={{
          "&.MuiContainer-root": {
            p: 0,
          },
        }}
        maxWidth="lg"
      >
        {!fetchedBlocks?.length && !loading && (
          <Box
            sx={{
              // backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "80px",
              // minHeight: "90vh",
            }}
          >
            <Box
              sx={{
                // backgroundColor: "red",
                width: "362px",
                // m: "auto",
                // mt: "7rem",
              }}
            >
              <img
                style={{
                  display: "block",
                  width: "156px",
                  margin: "0 auto",
                }}
                src={settingPageNoModulePNG}
                alt="setting Page PNG"
              />
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "150%",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                  color: "rgba(0, 0, 0, 0.6)",
                  mt: "8px",
                  mb: "24px",
                }}
              >
                No Settings found. Please create a Settings for Address
                Autocomplete Field Mapping for any Standard or Custom Module
              </Typography>
              <Tooltip
              // title={`Add Settings For ${module_for_buttons.plural_label} Module`}
              >
                <Button
                  sx={{
                    display: "flex",
                    margin: "0 auto",
                    lineHeight: "26px",
                    fontFamily: "Lato",
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "26px",
                    letterSpacing: "0.46px",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  size="medium"
                  onClick={handleOpen}
                >
                  <AddIcon sx={{ mr: "5px", fontSize: "16px" }} />
                  Add New Setting
                  {/* <Typography>ADD Settings</Typography> */}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        )}
        {!edit_custom_module && fetchedBlocks?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: "24px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "24px",
                fontFamily: "Lato",
              }}
            >
              All Module Settings
            </Typography>
            <Button
              variant="contained"
              size="medium"
              onClick={handleOpen}
              sx={{
                fontWeight: 500,
                fontFamily: "Lato",
                textTransform: "capitalize",
              }}
            >
              <AddIcon sx={{ mr: "5px", fontSize: "16px" }} />
              Module Settings
            </Button>
          </Box>
        )}
        <Box sx={{ display: custom_action ? "none" : "block" }}>
          {fetchedBlocks?.length > 0 && (
            <AddressBlockTables
              cronRunningModules={cronRunningModules}
              handleSubmit={handleSubmit}
              fetchedBlocks={fetchedBlocks}
              edit_custom_module={edit_custom_module}
              handleDelete={(blockData) => {
                setDeleteAddressInfo(blockData);
              }}
              handleEdit={(blockData) => {
                setOpen(true);
                setExistingData(blockData);
              }}
            />
          )}
        </Box>

        <Box sx={{ display: custom_action ? "block" : "none" }}>
          <Grid
            container
            sx={{
              minHeight: "100vh",
              // overflow: "hidden",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                bgcolor: "#FAFAFA",
                pt: "24px",
                px: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {fetchedBlocks?.length > 0 && (
                  <AddressBlockTables
                    cronRunningModules={cronRunningModules}
                    handleSubmit={handleSubmit}
                    custom_action={custom_action}
                    fetchedBlocks={fetchedBlocks}
                    edit_custom_module={edit_custom_module}
                    handleDelete={(blockData) => {
                      setDeleteAddressInfo(blockData);
                    }}
                    handleEdit={(blockData) => {
                      setOpen(true);
                      setExistingData(blockData);
                    }}
                  />
                )}
                <Button
                  variant="contained"
                  sx={{ mt: "auto", mb: 3 }}
                  onClick={() => handleSaveCustomAction(fetchedBlocks)}
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                bgcolor: "#F5F5F5",
                py: "24px",
                px: "16px",
                overflow: "hidden",
              }}
            >
              {custom_action && (
                <TableForCustomAction
                  blocks={fetchedBlocks?.[0].setting_schema?.blocks}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
