import React from "react";
import { Button, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";

import StartModalPage from "./ContentOfSlider1";

export default function Slider1({
  handleClose,
  handleNextPage,
  handleSelectedModule,
  handleFetchedModules,
  handleAddressComponentsValue,
  handleCountryRestrictions,
  modules,
  fetchedModules,
  existingData,
  data,
  api_key,
  for_settings,
  module_for_buttons,
  handleLanguage,
}) {
  return (
    <>
      <Box
        sx={{
          mt: "62px",
        }}
      >
        <StartModalPage
          handleSelectedModule={handleSelectedModule}
          handleFetchedModules={handleFetchedModules}
          handleAddressComponentsValue={handleAddressComponentsValue}
          handleCountryRestrictions={handleCountryRestrictions}
          modules={modules}
          fetchedModules={fetchedModules}
          selectedModule={!for_settings ? module_for_buttons : data.moduleName}
          selectedLanguage={data.language}
          handleLanguage={handleLanguage}
          formatted_address={data.formatted_address}
          restrictedCountries={data.country_restrictions}
          existingData={existingData}
          for_settings={for_settings}
          api_key={api_key}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mr: 2,
            mb: 2,
          }}
        >
          <Grid item>
            <Button
              sx={{ width: 100 }}
              size="small"
              onClick={handleClose}
              variant="outlined"
            >
              CANCEL
            </Button>
          </Grid>

          <Grid item>
            <Button
              sx={{ width: 100 }}
              size="small"
              disabled={
                (!for_settings ? module_for_buttons : data.moduleName) &&
                data.country_restrictions.length < 6 &&
                data.formatted_address
                  ? false
                  : true
              }
              onClick={handleNextPage}
              variant="contained"
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
