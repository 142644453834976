import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";

import Slider1 from "./Slider1/Slider1";
import Slider2 from "./Slider2/Slider2.js";
import "./style.css";

import Loader from "../components/loader/Loader";

const ZOHO = window.ZOHO;
const fieldMappingObj = {
  address_1: {
    id: "address_1",
    selected_field: null,
    google_response: "${street_number.long_name}, ${route.long_name}",
    value: "",
    allowedTypes: ["text", "country"],
    data_type: "text",
    label: "Address 1",
    required: true,
  },
  address_2: {
    id: "address_2",
    selected_field: null,
    google_response: "",
    value: "",
    allowedTypes: ["text", "country"],
    data_type: "text",
    label: "Address 2",
  },
  city: {
    id: "city",
    selected_field: null,
    google_response: "${locality.long_name}",
    value: "",
    allowedTypes: ["text"],
    data_type: "text",
    label: "City",
    required: true,
  },
  state: {
    id: "state",
    selected_field: null,
    google_response: "${administrative_area_level_1.short_name}",
    value: "",
    allowedTypes: ["text"],
    data_type: "text",
    label: "State",
    required: true,
  },
  zip: {
    id: "zip",
    selected_field: null,
    google_response: "${postal_code.long_name}",
    value: "",
    allowedTypes: ["text"],
    data_type: "text",
    label: "Zip",
    required: true,
  },
  country: {
    id: "country",
    selected_field: null,
    google_response: "${country.long_name}",
    value: "",
    allowedTypes: ["country"],
    data_type: "country",
    label: "Country",
  },
  latitude: {
    id: "latitude",
    selected_field: null,
    google_response: "${location.latitude}",
    value: "",
    allowedTypes: ["latitude"],
    data_type: "latitude",
    label: "Latitude",
  },
  longitude: {
    id: "longitude",
    selected_field: null,
    google_response: "${location.longitude}",
    value: "",
    allowedTypes: ["longitude"],
    data_type: "longitude",
    label: "Longitude",
  },
};
export function generateNewAddress(address_name) {
  return {
    address_block_name: address_name,
    fieldMapping: fieldMappingObj,
  };
}

export default function AddAddressModal({
  openModal,
  modules,
  existingData,
  fetchedModules,
  handleClose,
  handleFetchedModules,
  handleUpdateSettings,
  api_key,
  for_settings,
  module_for_buttons,
  selectedLanguage,
  orgId,
  zohoApiKey,
}) {
  const [open, setOpen] = useState(false);
  const [slider1Active, setSlider1Active] = useState(true);
  const [loadingModuleFields, setLoadingModuleFields] = useState(false);
  // start page
  const [address_components_values, set_address_components_values] =
    useState(null);

  //second page
  const [data, setData] = useState({
    moduleName: null,
    formatted_address: null,
    country_restrictions: [],
    selected_fields_in_all_blocks: {},
    blocks: [generateNewAddress("Address-1")],
  });

  const handleNextPage = () => setSlider1Active(false);
  const handleStartPage = () => setSlider1Active(true);

  useEffect(() => {
    // if user want to close the modal
    if (!openModal) {
      setOpen(false);
      return;
    }

    // if user want to open the modal
    setOpen(true);
    setSlider1Active(true);
    //this handle the edit portion
    if (existingData) {
      //module is not found in fetchedModules that means we have to fetch the module fields
      if (!fetchedModules[existingData.module_name]) {
        try {
          setLoadingModuleFields(true);
          ZOHO.CRM.META.getFields({ Entity: existingData.module_name }).then(
            function (data) {
              const filteredFields = data.fields.filter((field) => {
                if (
                  field.data_type === "text" ||
                  field.data_type === "picklist"
                ) {
                  return true;
                }
              });
              handleFetchedModules({
                [existingData.module_name]: filteredFields,
              });
              setLoadingModuleFields(false);
            }
          );
        } catch (err) {
          // alert(err);
        } finally {
        }
      }

      setData(existingData.setting_schema);
    }
    return () => {
      setData({
        moduleName: null,
        formatted_address: null,
        country_restrictions: [],
        selected_fields_in_all_blocks: {},
        blocks: [generateNewAddress("Address-1")],
      });
    };
  }, [openModal]);

  // start page
  const handleAddressComponentsValue = ({
    componentValues,
    formatted_address,
  }) => {
    set_address_components_values(componentValues);
    setData((prev) => ({
      ...prev,
      address_components_values: componentValues,
      formatted_address,
    }));
  };

  const handleSelectedModule = (module) => {
    setData((prev) => ({ ...prev, moduleName: module }));
  };

  const handleCountryRestrictions = (restrictedCountries) => {
    setData((prev) => ({ ...prev, country_restrictions: restrictedCountries }));
  };
  const handleLanguage = (value) => {
    setData((prev) => ({ ...prev, language: value }));
  };

  //second page
  const handleAddressBlockName = (address_block_name) => {
    setData((prev) => ({ ...prev, address_block_name: address_block_name }));
  };
  if (loadingModuleFields) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingModuleFields}
      >
        <Loader />
      </Backdrop>
    );
  }

  return (
    <Dialog
      sx={{
        "& .MuiDialog-scrollPaper": {
          alignItems: "flex-start",
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: "75rem",
          width: "100%",
          mt: 0,
          verticalAlign: "top",
          borderRadius: 0,
          p: 0,
          position: "relative",
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent sx={{ p: 0 }}>
        {slider1Active && (
          <Slider1
            handleClose={handleClose}
            handleNextPage={handleNextPage}
            handleAddressComponentsValue={handleAddressComponentsValue}
            handleSelectedModule={handleSelectedModule}
            handleFetchedModules={handleFetchedModules}
            handleCountryRestrictions={handleCountryRestrictions}
            modules={modules}
            existingData={existingData}
            fetchedModules={fetchedModules}
            data={data}
            api_key={api_key}
            for_settings={for_settings}
            module_for_buttons={module_for_buttons}
            handleLanguage={handleLanguage}
          />
        )}

        {!slider1Active && (
          <Slider2
            handleStartPage={handleStartPage}
            handleClose={handleClose}
            handleAddressBlockName={handleAddressBlockName}
            handleUpdateSettings={handleUpdateSettings}
            fetchedModules={fetchedModules}
            data={data}
            existingData={existingData}
            setData={setData}
            for_settings={for_settings}
            module_for_buttons={module_for_buttons}
            orgId={orgId}
            zohoApiKey={zohoApiKey}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
