import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../components/buttons/index";
import { LoadScript } from "@react-google-maps/api";

import Loader from "../components/loader/Loader";

const ZOHO = window.ZOHO;
window.ZOHO_MODAL_HEIGHT = "750px";

export default function Buttons() {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [moduleInfo, setModuleInfo] = useState(null);
  const [listViewWithoutRecordInfo, setListViewWithoutRecordInfo] =
    useState(null);
  const [modules, setModules] = useState([]);
  const [fetchedInfo, setFetchedInfo] = useState(null);
  const [show_settings_btn, set_show_settings_btn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchedModules, setFetchedModules] = useState({});
  const [apiKey, setApiKey] = useState(null);

  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();

  const handleFetchedModules = (module) => {
    setFetchedModules((prev) => ({
      ...prev,
      ...module,
    }));
  };

  const fetchDataOfThatModule = async () => {
    // fetch related module setting schema
    const updateData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${moduleInfo.module_name}`,
      method: "GET",
      headers: {
        orgid: orgId, // Org ID
        apikey: zohoApiKey, // API KEy
        connname: "easyaddressautocomplete", // Conn Name
      },
    });
    console.log({ updateData });
    setFetchedInfo({
      ...updateData.data.data[0],
      setting_schema: JSON.parse(updateData.data.data[0].setting_schema),
    });
    set_show_settings_btn(false);
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        if (data.Entity === "Potentials") {
          data.Entity = "Deals";
        }
        await ZOHO.CRM.UI.Resize({
          height: window.ZOHO_MODAL_HEIGHT,
          width: "1200",
        });
        const orgData = {
          apiKeys: [
            "easyaddressautocomplete__API_KEY",
            "easyaddressautocomplete__Organization_ID",
            "easyaddressautocomplete__ZAPI_Key",
            "easyaddressautocomplete__Data_Center_URL",
            "easyaddressautocomplete__Google_API_KEY",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
        console.log({ orgVariables });
        setDataCenterUrl(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Data_Center_URL?.value
            ? orgVariables?.Success?.Content
                ?.easyaddressautocomplete__Data_Center_URL?.value != "null"
              ? orgVariables?.Success?.Content
                  ?.easyaddressautocomplete__Data_Center_URL?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.easyaddressautocomplete__API_KEY
            ?.value
        );
        setOrgId(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Organization_ID?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.easyaddressautocomplete__ZAPI_Key
            ?.value
        );
        setApiKey(
          orgVariables?.Success?.Content
            ?.easyaddressautocomplete__Google_API_KEY?.value
        );

        const getAllModules = await ZOHO.CRM.META.getModules();

        console.log({ data, getAllModules });

        const filteredModule = getAllModules.modules.filter((module) => {
          if (
            module.api_name === data.Entity ||
            module.module_name === data.Entity
          ) {
            return true;
          }
        })[0];

        //added null cheker here which created the issue
        setModuleInfo({
          module_name: filteredModule?.api_name,
          plural_label: filteredModule?.plural_label,
          data: { ...data, Entity: filteredModule?.api_name },
          buttonPosition: data?.ButtonPosition || "CreateOrCloneView",
        });

        setZohoLoaded(true);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);
  useEffect(() => {
    if (zohoLoaded && moduleInfo) {
      (async () => {
        try {
          // fetch related module setting schema
          const updateData = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${moduleInfo.module_name}`,
            method: "GET",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: "easyaddressautocomplete", // Conn Name
            },
          });
          const organizationInfo = await ZOHO.CRM.CONFIG.getOrgInfo();

          ZOHO.CRM.META.getModules().then(function (data) {
            const filteredModules = data.modules.filter((module) => {
              if (
                module.global_search_supported &&
                module.visible &&
                module.editable &&
                module.show_as_tab
              ) {
                return true;
              }
            });
            setModules(filteredModules);

            const moduleNameForListviewWithoutRecord = filteredModules.find(
              (module) => {
                if (module.api_name === moduleInfo.module_name) {
                  return module.module_name;
                }
              }
            );

            setListViewWithoutRecordInfo({
              module_name: moduleNameForListviewWithoutRecord.module_name,
              organization_name: organizationInfo.org[0].domain_name,
            });
          });
          // fetch api key from organization variable
          // ZOHO.CRM.API.getOrgVariable("easyaddressautocomplete__Google_API_KEY").then(function (data) {

          //   setApiKey(data.Success.Content);
          // });

          //if there is no data for this module
          //that means show button for add setting so that user can create setting for this module
          if (!updateData.data.data) {
            ZOHO.CRM.META.getFields({ Entity: moduleInfo.module_name }).then(
              function (data) {
                const filteredFields = data.fields.filter((field) => {
                  if (
                    field.data_type === "text" ||
                    field.data_type === "picklist"
                  ) {
                    return true;
                  }
                });

                handleFetchedModules({
                  [moduleInfo.module_name]: filteredFields,
                });
              }
            );

            set_show_settings_btn(true);
            return;
          }

          setFetchedInfo({
            ...updateData.data.data[0],
            setting_schema: JSON.parse(updateData.data.data[0].setting_schema),
          });
        } catch (err) {
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [zohoLoaded, moduleInfo]);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loader />
      </Backdrop>
    );
  }

  return (
    apiKey && (
      <LoadScript
        googleMapsApiKey={apiKey}
        loadingElement={
          <div style={{ height: window.ZOHO_MODAL_HEIGHT }}>
            <Loader />
          </div>
        }
        libraries={["places"]}
        language={fetchedInfo?.setting_schema?.language?.code || "en"}
      >
        <Button
          ZOHO={ZOHO}
          api_key={apiKey}
          moduleInfo={moduleInfo}
          dataCenterUrl={dataCenterUrl}
          listViewWithoutRecordInfo={listViewWithoutRecordInfo}
          fetchedInfo={fetchedInfo}
          show_settings_btn={show_settings_btn}
          fetchedModules={fetchedModules}
          modules={modules}
          handleFetchedModules={handleFetchedModules}
          fetchDataOfThatModule={fetchDataOfThatModule}
          orgId={orgId}
          zohoApiKey={zohoApiKey}
          zapiKey={zapiKey}
        />
      </LoadScript>
    )
  );
}
