import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";

import apiIcon from "../../apiIcon.png";
import { CircularProgress } from "@mui/material";

export default function Authentication({
  ZOHO,
  dataCenterUrl,
  setDataCenterUrl,
  handleTableNumber,
  handleSnackBarError,
  prev_gooogle_api_key,
  zohoApiKey,
  setApiKey,
  handleAuthenticateZoho,
  handleRevokeZoho,
  authLoadingZoho,
  revokeLoadingZoho,
  zohoAuth,
  setSnackbarMsg,
  setSnackBarForError,
}) {
  const [api_key, set_api_key] = useState(null);

  const [zoho_api_key, set_Zoho_api_key] = useState(null);

  const dataCenterUrlOptions = [
    "https://accounts.zoho.com",
    "https://accounts.zoho.eu",
    "https://accounts.zoho.com.au",
    "https://accounts.zoho.in",
    "https://accounts.zoho.com.cn",
    "https://accounts.zoho.jp",
  ];

  const [disable, setDisable] = useState(!dataCenterUrl);

  useEffect(() => {
    set_api_key(prev_gooogle_api_key);
    set_Zoho_api_key(zohoApiKey);
  }, [prev_gooogle_api_key, zohoApiKey]);

  return (
    <Box sx={{ mx: 20, py: 1 }}>
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
                fontWeight: "bold",
              }}
            >
              Authenticate ZOHO Service
            </Typography>

            <Box
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "16px",
                fontWeight: 400,
                linetHeight: "24px",
                mb: 2,
              }}
            >
              Google Address Toolkit require to fetch/modify Record's Details
              and fields metadata of modules. To perform these, it requires Zoho
              CRM Service authenticate. Please click the button below to
              Authenticate Zoho CRM Service
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {zohoAuth ? (
                <Button
                  disabled={revokeLoadingZoho}
                  onClick={handleRevokeZoho}
                  sx={{
                    my: 1,
                    textTransform: "none",
                    width: 250,
                  }}
                  variant="contained"
                  size="small"
                >
                  {revokeLoadingZoho ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      loading
                      <CircularProgress
                        sx={{ color: "white", ml: 1 }}
                        size={17}
                      />{" "}
                    </Box>
                  ) : (
                    "Revoke Zoho CRM"
                  )}
                </Button>
              ) : (
                <Button
                  disabled={disable || authLoadingZoho}
                  onClick={() => {
                    handleAuthenticateZoho();
                  }}
                  sx={{
                    my: 1,
                    textTransform: "none",
                    width: 250,
                  }}
                  variant="contained"
                  size="small"
                >
                  {authLoadingZoho ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Verifying{" "}
                      <CircularProgress
                        sx={{ color: "white", ml: 1 }}
                        size={17}
                      />{" "}
                    </Box>
                  ) : (
                    "Authenticate"
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={apiIcon}
            alt="api PNG"
            style={{ display: "block", width: 220 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: 0.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px solid #cccccc",
          }}
        >
          <Box sx={{ width: "50%", m: 1 }}>
            <Typography
              sx={{
                my: 1,
                color: "rgba(0, 0, 0, 0.87)",
                fontWeight: "bold",
              }}
            >
              Google API Key
            </Typography>
            {/* <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000000",
                letterSpacing: "0.4px",
                mb: "12px",
              }}
            >
              Google API Key
            </Typography> */}
            <Box
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "16px",
                fontWeight: 400,
                linetHeight: "24px",
                mb: 2,
              }}
            >
              You need to provide a valid Googe API Key with Maps Platform API
              Activated. The API Key will be used to Generate Maps and fetch
              address components and geocodes
            </Box>

            <TextField
              inputProps={{ autocomplete: "off" }}
              sx={{ width: "100%" }}
              id="outlined-basic"
              // label="Google API Key"
              placeholder="Enter Google API Key"
              variant="outlined"
              size="small"
              value={api_key}
              onChange={(e) => {
                set_api_key(e.target.value?.trim());
              }}
            />
            <Typography
              sx={{
                width: "100%",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Lato",
                color: "rgba(0, 0, 0, 0.6)",
                letterSpacing: "0.4px",
                // px: "14px",
                mb: "20px",
                lineHeight: "20px",
              }}
            >
              {/* A Google API key is a unique identifier used to authenticate and
              authorize access to Google services. */}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ width: 255, textTransform: "none" }}
                variant="contained"
                size="small"
                onClick={async () => {
                  //before setting api key check wheather it is valid or not
                  const testApi = await axios(
                    `https://maps.googleapis.com/maps/api/geocode/json?place_id=ChIJeRpOeF67j4AR9ydy_PIzPuM&key=${api_key}`
                  );

                  if (testApi.data.status === "OK" && zohoAuth) {
                    //set the api key to crm variable and naviagte the user to settings tab
                    handleTableNumber(1);
                    setApiKey(api_key);
                    var data = {
                      apiname: "easyaddressautocomplete__Google_API_KEY",
                      value: api_key,
                    };
                    ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", data).then(
                      function (data) {}
                    );
                  } else {
                    if (testApi.data.status !== "OK" && !zohoAuth) {
                      setSnackbarMsg(
                        "Zoho is not authenticated and Google API Key is not valid"
                      );
                    } else if (testApi.data.status !== "OK") {
                      setSnackbarMsg(testApi.data.error_message);
                    } else if (!zohoAuth) {
                      setSnackbarMsg("Zoho is not authenticated");
                    }

                    setSnackBarForError(true);
                  }
                }}
              >
                Verify Google API
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
              ml: 2,
              pt: 1,
              px: 2,
              pb: 1,
              bgcolor: "rgba(25, 118, 210, 0.04)",
            }}
          >
            <Typography
              sx={{
                my: 0.5,
                color: "rgba(0, 0, 0, 0.87)",
                fontWeight: "bold",
              }}
            >
              How to Create Google API Key
            </Typography>
            {/* <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000000",
                letterSpacing: "0.4px",
                mb: "12px",
              }}
            >
              How to get Google API Key
            </Typography> */}
            <Box sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
              <Typography>
                Please follow the steps to Create Google API Key{" "}
              </Typography>
              <Typography>
                {" "}
                1. Go to{" "}
                <a
                  href="https://console.cloud.google.com/apis/credentials"
                  target="_blank"
                >
                  Google Cloud Console
                </a>{" "}
              </Typography>
              <Typography>
                2. Click 'Create Credentials' then click 'API Key'{" "}
              </Typography>
              <Typography>
                {" "}
                3. Once API Key is generated, click on that API Key and go to
                API Restriction{" "}
              </Typography>
              <Typography>
                4. You can choose 'Don't restrict key' or 'Restrict key'. If you
                select 'Restrict key' please add the following:{" "}
              </Typography>
              <Typography sx={{ ml: 3 }}>a. Geocoding API</Typography>
              <Typography sx={{ ml: 3 }}>b. maps JavaScript API</Typography>
              <Typography sx={{ ml: 3 }}>c. Places API</Typography>
              <Typography>
                {" "}
                5. Please make sure that,you enabled Billing and add Cards in
                your Google Cloud Platform.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
