import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const stHead = {
  display: "flex",
  justifyContent: "space-between",
  padding: "0.6rem",
};

const dotDotText = {
  //width: "30%",
  fontWeight: "bold",
  fontSize: "16px",
  LineHeight: "24px",
  letterSpacing: ".17px",
  color: "rgba(0, 0, 0, 0.87)",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export default function AddressBlockTableHead() {
  return (
    <Grid container>
      <Grid
        item
        sx={{
          ...stHead,
          display: "flex",
          background: "rgba(0, 0, 0, 0.04)",
          borderBottom: "1px solid #D3D3D3",
        }}
        xs={9}
      >
        <Typography sx={{ ...dotDotText, flex: 0.7 }}>Settings</Typography>
        <Typography sx={{ ...dotDotText, flex: 1 }}>Zoho Field</Typography>
        <Typography sx={{ ...dotDotText, flex: 3 }}>Google Response</Typography>
      </Grid>
      <Grid
        item
        sx={{
          ...stHead,
          background: "rgba(25, 118, 210, 0.04)",
          borderBottom: "1px solid #D3D3D3",
        }}
        xs={3}
      >
        <Typography sx={{ ...dotDotText, width: "100%" }}>Preview</Typography>
      </Grid>
    </Grid>
  );
}
