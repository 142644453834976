import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";

const DEFAULT_ROWS_PER_PAGE = 10;

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, head_cells } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" style={{ padding: "5px" }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {head_cells.map((headCell, index) => (
          <TableCell key={index} align={"left"} style={{ padding: "5px" }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const [loading, setLoading] = React.useState(false);
  const {
    selected_ids,
    row_data,
    set_row_data,
    fetchedInfo,
    googleApiKey,
    ZOHO,
  } = props;

  function customTrim(str, chars) {
    let start = 0;
    let end = str.length;

    while (start < end && chars.includes(str[start])) {
      start++;
    }

    while (end > start && chars.includes(str[end - 1])) {
      end--;
    }

    return str.slice(start, end);
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected_ids.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {selected_ids.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected_ids.length} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          No record is selected
        </Typography>
      )}

      {selected_ids.length > 0 && (
        <LoadingButton
          loading={loading}
          onClick={() => {
            setLoading(true);
            //find all the selected rows
            const selectedRows = Object.values(row_data).filter((data) => {
              if (selected_ids.indexOf(data.id) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            //find all the rows that we didnt select
            const unSelectedRows = Object.values(row_data).filter((data) => {
              if (selected_ids.indexOf(data.id) === -1) {
                return true;
              } else {
                return false;
              }
            });

            //grab info of the selected rows
            const data_we_need_to_process = selectedRows
              .map(({ blocks, id }) => {
                const result = [];

                Object.keys(blocks).forEach((blockNumber) => {
                  const block = Number(blockNumber);
                  const record_id = id;
                  const full_address = blocks[blockNumber].full_address;

                  result.push({ record_id, block, full_address });
                });
                return result;
              })
              .flat();

            //get address realted info using full address from google geocode
            const fetchDataFromGeoCode = ({ full_address, ...rest }) => {
              if (!full_address) return [];
              return new Promise(async (resolve, reject) => {
                //encode full_address
                const encoded_full_address = encodeURIComponent(full_address);
                axios(
                  `https://maps.googleapis.com/maps/api/geocode/json?address=${encoded_full_address}&language=${
                    fetchedInfo.setting_schema?.language?.code || "en"
                  }&key=${googleApiKey}`
                )
                  .then((res) => {
                    return res.data;
                  })
                  .then((res) => {
                    resolve({ ...res, ...rest });
                  })
                  .catch((err) => {
                    resolve({
                      ...{ ...rest },
                      status: "ERROR",
                      errMsg: `error____${err.response.data.status}____${err.response.data.error_message}`,
                    });
                  });
              });
            };

            // Fetch data from geocoding
            Promise.all(
              data_we_need_to_process.flatMap((data) =>
                fetchDataFromGeoCode(data)
              )
            )
              .then((res) => {
                //we store all the record id's info into the data that we will use to update records later
                const data = {};
                //we store those records if we dont find any results from geocode for the full address
                const data_with_zero_results = [];
                //we store those records if we find error from geocode for the full address
                const records_with_error = [];

                //iterate through all records
                //do all the calculation and populate all the values to the right fields
                //after that populate all the records
                res.forEach((record) => {
                  if (record.status.toLowerCase() === "ok") {
                    const values = {};

                    record.results[0].address_components.forEach(
                      (address_component) => {
                        address_component.types.forEach((type) => {
                          if (type !== "political") {
                            values[type] = {
                              long_name: address_component.long_name,
                              short_name: address_component.short_name,
                            };
                          }
                        });
                      }
                    );

                    values.location = {
                      latitude: record.results[0].geometry.location.lat,
                      longitude: record.results[0].geometry.location.lng,
                    };

                    let selectedValues = {};

                    //fetch setting_schema for that specidfic block
                    const { fieldMapping } =
                      fetchedInfo?.setting_schema?.blocks[record.block];
                    const {
                      address_1,
                      address_2,
                      state,
                      city,
                      zip,
                      country,
                      latitude,
                      longitude,
                    } = fieldMapping;
                    const partsOfAddress = [
                      address_1,
                      address_2,
                      state,
                      city,
                      zip,
                      country,
                      latitude,
                      longitude,
                    ];

                    partsOfAddress.forEach((singleAddress, index) => {
                      if (
                        singleAddress.selected_field &&
                        singleAddress.google_response
                      ) {
                        let str = singleAddress.google_response;
                        const regex = /\${[\w._]+}/g;
                        const matches = str.match(regex);

                        matches?.forEach((match) => {
                          const regex = /(\w+)\.(\w+)/;
                          const matches = match.match(regex);
                          const first_word = matches[1];
                          const second_word = matches[2];

                          const value = values[first_word]?.[second_word] || "";

                          str = str.replace(
                            new RegExp(`\\${match}`, "g"),
                            value
                          );

                          if (
                            singleAddress.id != "longitude" &&
                            singleAddress.id != "latitude"
                          ) {
                            str = customTrim(str, "-, ");
                          }

                          selectedValues[
                            singleAddress.selected_field.api_name
                          ] = str;
                        });
                      }
                    });

                    data[record.record_id] = {
                      ...data[record.record_id],
                      [record.block]: {
                        data: {
                          ...data[record.record_id],
                          ...selectedValues,
                        },
                        block: record.block,
                        record_id: record.record_id,
                      },
                    };
                  } else if (
                    record.status.toLowerCase() === "ZERO_RESULTS".toLowerCase()
                  ) {
                    data_with_zero_results.push(record);
                  } else if (
                    record.status.toLowerCase() === "ERROR".toLowerCase()
                  ) {
                    records_with_error.push(record);
                  }
                });

                const populateRecord = (record_field) => {
                  const config = {
                    Entity: fetchedInfo.module_name,
                    APIData: {
                      id: record_field.record_id,
                      ...record_field.data,
                    },
                    Trigger: ["workflow"],
                  };

                  return ZOHO.CRM.API.updateRecord(config)
                    .then((data) => {
                      return {
                        data: data.data[0],
                        id: record_field.record_id,
                        block: record_field.block,
                        ok: true,
                      };
                    })
                    .catch((err) => {
                      return {
                        err: err,
                        id: record_field.record_id,
                        block: record_field.block,
                        ok: false,
                      };
                    });
                };

                let allPromises = [];
                // grab all the info from data that we need to fetch and push it to the allPromises
                Object.keys(data)
                  .map((record_id) => Object.values(data[record_id]))
                  .flat()
                  .forEach((record_field) =>
                    allPromises.push(populateRecord(record_field))
                  );

                //update crm record data
                Promise.all(allPromises)
                  .then(async (res) => {
                    //the following code will handle to update all the records status
                    let updated_row_data = row_data;
                    res.forEach((record) => {
                      updated_row_data[record.id].blocks[record.block].status =
                        record.data.status;
                    });

                    //the following code will handle those records status where we didnt find any result from google geo code

                    data_with_zero_results.forEach((record) => {
                      updated_row_data[record.record_id].blocks[
                        record.block
                      ].status = "Nothing Found";
                    });

                    //the following code will handle those records status where we got error from google geo code
                    records_with_error.forEach((record) => {
                      updated_row_data[record.record_id].blocks[
                        record.block
                      ].status = record.errMsg;
                    });

                    //the following code will handle to update all the unselected row's status
                    unSelectedRows.forEach((record) => {
                      const all_record_blocks = Object.keys(
                        updated_row_data[record.id].blocks
                      );
                      all_record_blocks.forEach((blockNumber) => {
                        const status =
                          updated_row_data[record.id].blocks[blockNumber]
                            .status;
                        updated_row_data[record.id].blocks[blockNumber].status =
                          status === "success" ||
                          status.trim().toLowerCase() === "Nothing Found"
                            ? ""
                            : status;
                      });
                    });

                    set_row_data({ ...updated_row_data });
                    setLoading(false);
                  })
                  .then(() => {
                    setTimeout(() => {
                      ZOHO.CRM.UI.Popup.closeReload().then(function (data) {});
                    }, 2000);
                  })
                  .catch((err) => {
                    setLoading(false);
                  });
              })
              .catch((err) => {
                setLoading(false);
              });
          }}
          variant="contained"
          size="small"
        >
          Process
        </LoadingButton>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function NewTable({
  head_cells,
  row_data,
  set_row_data,
  fetchedInfo,
  googleApiKey,
  ZOHO,
}) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [visibleRows, setVisibleRows] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);

  React.useEffect(() => {
    let rowsOnMount = Object.values(row_data);

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    setVisibleRows(rowsOnMount);
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = Object.values(row_data).map((n) => n.id);

      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);

      const updatedRows = Object.values(row_data).slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );

      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows =
        newPage > 0
          ? Math.max(
              0,
              (1 + newPage) * rowsPerPage - Object.values(row_data).length
            )
          : 0;

      const newPaddingHeight = 33 * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [rowsPerPage]
  );

  const handleChangeRowsPerPage = React.useCallback((event) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(updatedRowsPerPage);

    setPage(0);

    const updatedRows = Object.values(row_data).slice(
      0 * updatedRowsPerPage,
      0 * updatedRowsPerPage + updatedRowsPerPage
    );

    setVisibleRows(updatedRows);

    // There is no layout jump to handle on the first page.
    setPaddingHeight(0);
  }, []);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <EnhancedTableToolbar
          selected_ids={selected}
          row_data={row_data}
          set_row_data={set_row_data}
          fetchedInfo={fetchedInfo}
          googleApiKey={googleApiKey}
          ZOHO={ZOHO}
        />
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={Object.values(row_data).length}
              head_cells={head_cells}
            />
            <TableBody>
              {visibleRows
                ? visibleRows.map(({ name, id, blocks }, blockIndex) => {
                    const isItemSelected = isSelected(id);
                    const labelId = `enhanced-table-checkbox-${blockIndex}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, name, id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          padding="checkbox"
                          style={{ padding: "2px" }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          style={{ padding: "2px" }}
                        >
                          {name}
                        </TableCell>

                        {Object.values(blocks).map((block, blockIndex) => {
                          return Object.keys(block).map((blockKey, index) => {
                            let statusColor = "";
                            if (blockKey === "status") {
                              if (block[blockKey].includes("error")) {
                                statusColor = "error";
                              } else if (block[blockKey].includes("success")) {
                                statusColor = "success";
                              } else if (
                                block[blockKey]
                                  .toLowerCase()
                                  .includes("nothing")
                              ) {
                                statusColor = "warning";
                              } else {
                                statusColor = "info";
                              }

                              return block[blockKey].includes("error") ? (
                                <Tooltip
                                  title={block[blockKey].split("____")[2]}
                                >
                                  <TableCell
                                    align="left"
                                    key={index}
                                    style={{ padding: "2px" }}
                                  >
                                    <Chip
                                      label={block[blockKey].split("____")[1]}
                                      color="error"
                                      size="small"
                                      variant="outlined"
                                    />
                                  </TableCell>
                                </Tooltip>
                              ) : (
                                <TableCell
                                  align="left"
                                  key={index}
                                  style={{ padding: "2px" }}
                                >
                                  {block[blockKey] && blockKey === "status" && (
                                    <Chip
                                      label={block[blockKey]}
                                      color={statusColor}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                align="left"
                                key={block[blockKey].id}
                                style={{ padding: "2px" }}
                              >
                                {block[blockKey]}
                              </TableCell>
                            );
                          });
                        })}
                      </TableRow>
                    );
                  })
                : null}
              {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: 0,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Object.keys(row_data).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
