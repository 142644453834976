import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({
  open,
  loading,
  handleClose,
  handleAgree,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Do you want to delete this address?
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" size="small">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleAgree}
          loadingPosition="start"
          variant="contained"
          size="small"
        >
          Ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
