import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useTransition,
} from "react";
import TextField from "@mui/material/TextField";
import { useCaretPosition } from "react-use-caret-position";
import "./style.css";

import getCaretCorordinate from "./getCaretCorordinate";

function filterOut(text, cursor) {
  const beforeCursor = text.slice(0, cursor);
  const afterCursor = text.slice(cursor, text.length);

  const filterdBeforeCursor = beforeCursor;
  const filterAfterCursor = afterCursor;

  const newText = filterdBeforeCursor + filterAfterCursor;
  const newCursor = filterdBeforeCursor.length;

  return [newText, newCursor];
}

function _Custom_AutoComplete({
  allowedTypes,
  data_type,
  fields,
  onChangeText,
  label,
  style,
  defaultValue,
  searchableFieldName,
  popoverHeight,
  popoverWidth,
}) {
  const [isPending, startTransition] = useTransition();
  const [moduleFields, setModuleFields] = useState(null);
  const [name, setName] = useState(defaultValue || "");
  const [openPopover, setOpenPopover] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [startSearchPosition, setStartSearchPosition] = useState(null);
  const [endSearchPosition, setEndSearchPosition] = useState(null);

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  useEffect(() => {
    debounce(() => {
      onChangeText(name);
      updateCaret();
    })();
  }, [name]);

  useEffect(() => {
    const obj = {};
    fields.forEach((field) => {
      const isItValidType = allowedTypes.findIndex(
        (data_type) => data_type === field.data_type
      );
      if (!obj[field.module_name]) {
        obj[field.module_name] = {
          module_name: field.module_name,
          module_label: field.module_label,
          fields: isItValidType > -1 ? [field] : [],
        };
        return;
      }

      if (obj[field.module_name]) {
        obj[field.module_name].fields =
          isItValidType > -1
            ? [...obj[field.module_name].fields, field]
            : [...obj[field.module_name].fields];
      }
    });

    setModuleFields(obj);
  }, []);

  const { ref: textFieldRef, start, end, updateCaret } = useCaretPosition();
  function useRunAfterUpdate() {
    const afterPaintRef = useRef(null);
    useLayoutEffect(() => {
      if (afterPaintRef.current) {
        afterPaintRef.current();
        afterPaintRef.current = null;
      }
    });
    const runAfterUpdate = (fn) => (afterPaintRef.current = fn);
    return runAfterUpdate;
  }
  const runAfterUpdate = useRunAfterUpdate();

  function setCaretPosition(textFieldRef, caretPos) {
    var el = textFieldRef.current;

    // ^ this is used to not only get "focus", but
    // to make sure we don't have it everything -selected-
    // (it causes an issue in chrome, and having it doesn't hurt any other browser)

    if (el !== null) {
      if (el.createTextRange) {
        var range = el.createTextRange();
        range.move("character", caretPos);
        range.select();
        return true;
      } else {
        // (el.selectionStart === 0 added for Firefox bug)
        if (el.selectionStart || el.selectionStart === 0) {
          el.focus();
          el.setSelectionRange(caretPos, caretPos);
          return true;
        } else {
          // fail city, fortunately this never happens (as far as I've tested) :)
          el.focus();
          return false;
        }
      }
    }
  }

  const getCaretPosition = (evt) => {
    const input = evt.target;
    const text = input.value;
    const cursor = input.selectionStart;
    const [newName, newCursor] = filterOut(text, cursor);

    runAfterUpdate(() => {
      input.selectionStart = newCursor;
      input.selectionEnd = newCursor;
    });
    return {
      startCaretPosition: input.selectionStart,
      endCaretPosition: input.selectionEnd,
    };
  };
  const handleNameChange = (evt) => {
    const value = evt.target.value;

    // if data_type is not textarea or text user can not type except #
    if (
      data_type !== "textarea" &&
      data_type !== "text" &&
      !value.includes("#")
    ) {
      return;
    }

    //set name
    setName(value);
    //update endSearchPosition
    setEndSearchPosition(end);

    if (startSearchPosition > end) {
      setEndSearchPosition(null);
      setStartSearchPosition(null);
      setOpenPopover(false);
    }
    //update caret
    updateCaret();
  };
  const handlePopoverClose = () => {
    setOpenPopover(false);
  };

  const handleKeywordChange = ({ api_name, data_type, field }) => {
    const keywordValue =
      "$" +
      "{" +
      (field.module_name.toString().toUpperCase() !== "CUSTOM"
        ? field?.lookupfield_api_name || field.module_name
        : "Custom") +
      "." +
      field.api_name +
      "}";

    //if to module field's data_type is text or textarea
    if (data_type === "text" || data_type === "textarea") {
      setName(
        (prevValue) =>
          prevValue.slice(0, startSearchPosition) +
          keywordValue +
          prevValue.slice(endSearchPosition)
      );
      updateCaret();
      setStartSearchPosition(null);
      setEndSearchPosition(null);
      handlePopoverClose();
      setTimeout(() => {
        setCaretPosition(
          textFieldRef,
          keywordValue.toString().length + startSearchPosition
        );
      }, 5);
      return;
    }
    setName(keywordValue);

    updateCaret();
    setStartSearchPosition(null);
    setEndSearchPosition(null);
    textFieldRef.current.focus();
    handlePopoverClose();
  };

  const handlePopoverPosition = (endCaretPosition, event) => {
    // get caret position
    var coordinates = getCaretCorordinate(event.target, endCaretPosition, {
      debug: false,
    });

    //outer width of the window
    const windowouterWidth = window.innerWidth;
    //inner height of the window
    const windowInnerHeight = window.innerHeight;

    //popover width
    let popOverWidth = popoverWidth || 152;
    //popover height
    let popOverHeight = popoverHeight || 270;

    //popOver position
    let left;
    let top;

    const calculateWidth =
      windowouterWidth -
      (event.target.getClientRects()["0"].left +
        coordinates.left +
        popOverWidth);
    const isThereRoomForPopoverWidth = calculateWidth > popOverWidth;
    const isThereRoomForPopoverHeight =
      windowInnerHeight -
        (event.target.getClientRects()["0"].top + coordinates.top) >
      popOverHeight;

    left = coordinates.left + 15;

    if (isThereRoomForPopoverHeight) {
      top = coordinates.top + 25;
    } else {
      top = coordinates.top - popOverHeight;
    }

    setLeft(left);
    setTop(top);
    startTransition(() => {
      setOpenPopover(true);
    });
  };

  const getFilter = ({ fields, search, searchableFieldName }) => {
    // if the length of fields is zero then return no fields
    if (fields.length === 0) return { isEmpty: true };

    //this will handle when user open the popup for the first time(without searching anything after opening popup)
    if (name.slice(startSearchPosition + 1, endSearchPosition) === "") {
      return { isEmpty: false, fields };
    }

    const filterFields = [];
    fields.forEach((field) => {
      if (
        field[searchableFieldName]
          ?.toUpperCase()
          ?.includes(
            search
              .slice(startSearchPosition + 1, endSearchPosition)
              .toUpperCase()
          )
      ) {
        filterFields.push(field);
      }
    });

    //if the length of filterfields is zero then return no fields otherwise return filterfields
    if (filterFields.length > 0) {
      return { isEmpty: false, fields: filterFields };
    } else {
      return { isEmpty: true };
    }
  };

  const getModules = ({ modules, search }) => {
    // let atleastFieldsFoundOnce = 0;
    let updatedModules = [];
    modules.forEach((module) => {
      const { isEmpty, fields } = getFilter({
        fields: module.fields,
        search,
        searchableFieldName,
      });
      if (!isEmpty) {
        // atleastFieldsFoundOnce += 1;
        updatedModules.push({
          module_name: module.module_name,
          module_label: module.module_label,
          fields,
        });
      }
    });

    return updatedModules.length > 0
      ? updatedModules
      : [{ no_fields_found: true }];
  };

  return (
    <>
      {/* Overlay */}
      {openPopover && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,.1)",
            zIndex: 99998,
          }}
          onClick={() => {
            setOpenPopover(false);
          }}
        ></div>
      )}
      <div style={{ position: "relative" }}>
        <TextField
          label={label}
          id="outlined-size-small"
          size="small"
          inputRef={textFieldRef}
          value={name}
          minRows={1}
          multiline
          sx={{
            ...style,
          }}
          inputProps={{ autocomplete: "off" }}
          onChange={handleNameChange}
          onKeyUp={(evt) => {
            const { startCaretPosition, endCaretPosition } =
              getCaretPosition(evt);

            //update caret when shift key is not pressed
            if (!evt.shiftKey) {
              updateCaret();
              //set endSearchPosition
              setEndSearchPosition(endCaretPosition);
            }

            //when caret is over to #,popup will open
            if (name.slice(endCaretPosition - 1, endCaretPosition) === "#") {
              setStartSearchPosition(endCaretPosition - 1);
              setEndSearchPosition(endCaretPosition);
              handlePopoverPosition(endCaretPosition, evt);
              return;
            }

            //if there is an empty space after # then popup will close
            if (
              name.slice(startSearchPosition + 1, startSearchPosition + 2) ===
                " " &&
              openPopover
            ) {
              setEndSearchPosition(null);
              setStartSearchPosition(null);
              setOpenPopover(false);
              return;
            }

            //if caret position is less than the startSearchPosition(this startSearchPosition is responsible for opening popup)
            if (
              endCaretPosition === startSearchPosition ||
              endCaretPosition < startSearchPosition
            ) {
              setEndSearchPosition(null);
              setStartSearchPosition(null);
              setOpenPopover(false);
            }
          }}
        />
        <div
          style={{
            display: openPopover ? "block" : "none",
            position: "absolute",
            transition: "top 0.02s ease",
            top: `${top}px`,
            left: `${left}px`,
            background: "#fff",
            border: "1px solid #dedede",
            boxShadow: "0 0 5px 2px rgb(0 0 0 / 10%)",
            zIndex: 99999,
          }}
        >
          <div
            style={{
              overflowY: "auto",
              height: popoverHeight + "px",
              width: popoverWidth + "px",
              overflowX: "hidden",
              background: "#fff",
            }}
          >
            {openPopover &&
              getModules({
                modules: Object.values(moduleFields),
                search: name,
              }).map((module, index) => {
                if (module.no_fields_found) {
                  setOpenPopover(false);
                  return;
                }

                return (
                  <div key={index}>
                    <h3
                      style={{
                        background: "#f2f2f2",
                        padding: "5px 8px",
                      }}
                    >
                      {module.module_label}
                    </h3>
                    {module.fields.map((field, index) => {
                      return (
                        <p
                          key={field.id}
                          onClick={() =>
                            handleKeywordChange({
                              api_name: field.api_name,
                              data_type: data_type,
                              field,
                            })
                          }
                          style={{
                            padding: "2px 14px",
                            cursor: "pointer",
                          }}
                        >
                          {field.field_label}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default _Custom_AutoComplete;
