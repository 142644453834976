import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Grid from "@mui/material/Grid";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default function ModalForDeleteBlock({
  open,
  handleClose,
  deleteCurrentBlock,
}) {
  const handleCloseDeleteBtn = () => {
    handleClose();
    deleteCurrentBlock();
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <Typography sx={{ fontWeight: "bold" }}>
            Delete this address block?
          </Typography>
          <Typography variant="body1" sx={{ my: "1rem" }}>
            Are you sure to delete this address block?
          </Typography>
          <Grid container spacing={2}>
            <Grid item sx={{ marginLeft: "auto" }}>
              <Button onClick={handleClose}>CANCEL</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCloseDeleteBtn} sx={{ color: "red" }}>
                DELETE
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
